.map-section-control {
	background-color: rgba(245, 245, 245, 0.7); // Duplicate the one from google on the bottom of the map
	padding-right: 10px;
	input {
		float: right;
		margin-bottom: 0;
		margin-top: 2px;
	}
	label {
		pointer-events: none;
	}
}
