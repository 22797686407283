.view-logbook {
	.logbook-map-wrapper {
		height: 250px;
		position: relative;
	}
	.st-content {
		overflow: hidden;
	}
	ui-gmap-google-map {
		position: absolute;
		left: 0; right: 0; top: 0; bottom: 0;
	}
	.angular-google-map, .angular-google-map-container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	.logbook-map-buttons {
		float: left;
		width: 100%;
		padding-left: $form-spacing * .5;
		form {
			float: left;
		}
		.report-export.button {
			float: right;
			margin-bottom: 0;
		}
		.notice {
			display: inline-block;
		}
	}
	.logbook-level1, .asset-logbook-level1, .logbook-level2, .logbook-level3, .logbook-level4, .asset-logbook-level2 {
		overflow-x: auto;

		table {
			word-break: inherit !important;
		}
		> table.infos {
			margin-bottom: 0;
		}

		table tr {
			td {
				vertical-align: top;
				&.actions, &.icon, &.date {
					vertical-align: middle;
				}
			}
			.gps-time, .speed, .status-time, .address, .start, .stop, .location, .pause, .duration.exact, .status-title {
				@include small-type;
			}
			.date, .start, .stop, .latitude, .longitude {
				white-space: nowrap;
			}
		}
	}
	.logbook-level3 {
		table.grouping  {
			margin-bottom: 0;
			border-top-width: 0;
			td {
				font-weight: bold;
				span {
					font-size: inherit;
					color: inherit;
				}
			}
			td:not(.title) {
				min-width: 7.5em;
			}
			td.title { width: 100%; }
			td.expand { min-width: 0; }
		}
		.grouping-and-infos:nth-of-type(1) table.grouping {
			border-top-width: $table-border-size;
		}
		.duration, .distance {
			@include small-type;
		}
		table.infos {
			margin-bottom: 0;
			border-top: 0;
			 tr {
				.start.time, .stop.time {
					@include inherit-type;
				}
			}
		}
		.chart table {
			img { // We want crisp edges https://developer.mozilla.org/en/docs/Web/CSS/image-rendering
				-ms-interpolation-mode: nearest-neighbor;
				image-rendering: -webkit-optimize-contrast;
				image-rendering: -moz-crisp-edges;
				image-rendering: pixelated;
			}
		}
	}
	.logbook-level4, .asset-logbook-level2 {
		table {
			tr:not(.last-row) td span {
				@include small-type;
			}
		}
	}
	.result-count {
		text-align: right;
		display: block;
	}
	.angular-google-map-container img { // Override img styles set by foundation (would break gmaps icons on the map)
		display: initial;
		max-width: none;
	}
	.infostars-map-infobox {
		&, .infostars-map-infobox-header {
			border: 0; margin: 0;
		}
		td {
			padding: 0;
			& > * {
				font-size: $small-font-size;
			}
		}
	}
}
