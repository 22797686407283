.view-falogbook {
	.st-content {
		overflow: hidden;
	}
	.grid-toggle {
		position: absolute;
		right: 0;
		top: 50%;
		background-color: $shiny-edge-active-color;
		z-index: 20000;
		padding-left: rem-calc(4);
		padding-right: rem-calc(4);
		text-align: right;
		i {
			color: $primary-color;
			font-size: 24px;
			margin: auto;
		}
	}
	.falogbook-container {
		flex-flow: row nowrap; // Need to disable wrapping. This works around a bug in foundation. Otherwise the map will be as long as the (overflowing) list on Firefox and users would have to scroll to see the full map. Support for 'frame' grids might solve that after a foundation upgrade
	}

	.falogbook-map-wrapper {
		min-height: 200px;
		flex-grow: 1;
	}
	.falog-light-grid{
		flex-shrink: 1;
		flex-grow: 1; // Give up and take size when map is resized
		max-width: none;
	}
	ui-gmap-google-map {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.angular-google-map, .angular-google-map-container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}

	.angular-google-map-container img { // Override img styles set by foundation (would break gmaps icons on the map)
		display: initial;
		max-width: none;
	}
	.infostars-map-infobox {
		&, .infostars-map-infobox-header {
			border: 0;
			margin: 0;
		}
		td {
			padding: 0;
			& > * {
				font-size: $small-font-size;
			}
		}
	}
	.red {
		color: red;
	}

	.option-btn {
		margin-bottom: 0;
	}
	iframe.falogbook {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.tour-action-panel {
		button {
			margin-bottom: 3px;
			cursor: pointer;
		}
	}

	.no-results-container {
		top: inherit !important;
	}

	#editor {
		border-left: 2px solid #EAEAEA;
		min-height: 500px;
		textarea {
			resize: none;
		}
	}

	#conflict-panel {
		.red {
			color: red;
		}
	}

	//.zone-map {
	//	height: 300px;
	//	width: 50%;
	//	max-width: 50%;
	//	margin-bottom: 10px;
	//	margin-right: 10px;
	//	float: right;
	//	position: absolute;
	//}

	.mbottom20 {
		margin-bottom: 20px !important;
	}

	#tourForm.ng-submitted {
		input.ng-invalid, textarea.ng-invalid {
			margin-bottom: 0;
		}
		.invalid-text {
			color: #c90000;
			font-size: 12px;
			margin-bottom: 11px;
		}
	}

	.pagination {
		input {
			width: 60px;
			text-align: center;
		}
		.page-number {
			padding-top: 3px;
			font-size: 15px;
		}
	}

	#tourTable {
		td {
			a {
				margin-bottom: 0;
			}
		}
		.deleted {
			td {
				color: #c90000;
			}
		}
		tbody {
			tr {
				td {
					font-size: 0.75rem;
				}
			};
		}

	}

	.purpose-suggestion-box {
		border: 1px solid #e1e1e1;
		padding: 5px;
		margin-top: -11px;
		z-index: 1000;
		position: absolute;
		background-color: #F5F5F5;
		max-height: 125px;
		overflow-y: scroll;
	}

	.purpose-suggestion-box-option {
		cursor: pointer;
		padding-bottom: 5px;
	}

	.purpose-suggestion-box-option:hover {
		background-color: #e1e1e1;
	}

	#conflictsTable {
		.conflictRow {
			background: #c6c5c5 !important;
			font-weight: bold;

			td {
				font-size: 16px;
			}
		}
		.firstConflictTour {
			background: #F3F2F2 !important;
		}
		.secondConflictTour {
			background: white !important;
		}
		.conflict {
			color: red !important;
		}
	}

	.inline {
		display: inline;
	}

	#reportForm {
		button {
			margin-left: 0.9375rem;
			transition: none !important;
		}
		.mtop20 {
			margin-top: 2rem;
		}
	}

	.sortable {
		cursor: pointer;
	}

	.tour-status-select {
		min-width: 80px;
		font-size: 12px;
	}

	.tour-status-select-small {
		min-width: 30px;
		max-width: 30px;
	}

	.tour-purpose-input {
		font-size: 13px;
	}

	.tour-purpose-input::placeholder,
	.tour-purpose-input::-webkit-input-placeholder {
		color: blue;
		opacity: 0.7;
		font-style: italic;
	}

	.tour-purpose-selector {
		display: inline-block;
		color: white;
		height: 1.84375rem;
		background-color: #db6314;
		margin-left: -4px;
		margin-top: -5px;
		padding-top: 6px;
		cursor: pointer;

		i {
			display: block;
			text-align: center;
			cursor: pointer
		}
	}

	.combined {
		color: #999999;
	}

	.warning {
		font-weight: bold;
		color: #f0ad4e;
	}

	.stop-details {
		font-size: 0.7rem;
	}

	/* Large and up */
	@media screen and (max-width: 1199px) {
		#listPanel {
			&.sharing {
				display: none !important;
			}
		}
	}

	@media only screen and (min-width: 64.063em){
		#tourTable {
			tbody {
				tr {
					td {
						font-size: 0.825rem;
					}
				}
			}
		}
	}
}
