.vehicle-tree {

	.group-node {
		margin-bottom: 5px;

		div {
			img {
				vertical-align: middle;
			}

			label {
				vertical-align: middle;
			}

			input {
				vertical-align: middle;
				margin-bottom: 0;
			}
		}
		.open-image {
			width: 28px;
			margin-right: 3px;
			cursor: pointer;
			padding: 4px;
		}
		.leaf .open-image {
			opacity: 0;
		}

		.node-title {
			font-size: 80%;
			font-weight: bold;
		}
	}

	div {
		.subgroup-node {
			margin-left: 10px;
		}
	}

	.vehicle-element {
		margin-left: 40px;
		padding-top: 10px;

		.vehicle-title {
			font-size: 70%;
			display: inline;
		}

		img {
			margin-left: 5px;
			margin-right: 5px;
		}

		label {
		}

	}

	.child-nodes {
		margin-top: 5px;
	}

	// Medium & down
	@media screen and (max-width: 40.0625em) {
		.group-node {
			margin-bottom: 15px;
		}

		.vehicle-element {
			padding-bottom: 10px;
		}

		.child-nodes {
			margin-top: 10px;
		}
	}

}
