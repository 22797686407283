.view-alarmstar {
	&.alarmstar-container {
		flex-flow: row nowrap; // Need to disable wrapping. This works around a bug in foundation. Otherwise the map will be as long as the (overflowing) list on Firefox and users would have to scroll to see the full map. Support for 'frame' grids might solve that after a foundation upgrade
	}

	.validation {
	   color: red;
	}

	/**
	 * Google Maps
	 */
	table.alarmstar tr {
		.gps-time, .speed, .status-time, .address {
			@include small-type;
		}
	}

	.angular-google-map-container img { // Override img styles set by foundation (would break gmaps icons on the map)
		display: initial;
		max-width: none;
	}

	.zone-map {
		height: 300px;
		width: 50%;
		max-width: 50%;
		margin-bottom: 10px;
		margin-right: 10px;
		float: right;
	}

	/**
	 * General Design
	 */

	.full-width {
		width: 100%;
		max-width: 100%;
	}

	.st-content {
		overflow: hidden;
	}

	#mainView {
		border: 1px solid black;
		clear: both;
		padding: 0 1em;
	}

	.active {
		background-color: #FFF;
		color: #000;
	}


	/*
	 * Tab 1: Alarms
	 */

	.filter {
	}

	/*
	 * Tab 3: Create Routine
	 */
	.ltcol, .ctcol, .rtcol {
		//width: 30%;
		margin: 0 10px;
		padding: 10px;
	}

	.ltcol, .ctcol {
		width: 27%;
		float: left;
	}

	.rtcol {
		width: 36%;
		float: left;
	}


	/**
	 * Time
	 */

	.time-label {
	   float: left;
	   width: 2.8em;
	   line-height: 30px;
	}

	.day-label {
	   float: left;
	   width: 1.7em;
	   line-height: 30px;
	}

	.day-cbx {
	   float: left;
	   height: 30px;
	   margin-right: 1em;
	}

	.time {
	   float: left;
	   width: 6em;
	   margin-right: 1em;
	   height: 30px;
	}

	.date {
	   float: left;
	   width: 8em;
	   margin-right: 1em;
	   height: 30px;
	}

	.time-cbx {
	   float: left;
	   height: 30px;
	}

	.auto-width {
		width: auto;
	}

	.float-left {
		float: left;
	}

	.float-right {
		float: right;
	}

	.debug-info {
		font-size: 1em;
	}


	/**
	 * Block
	 */

	.block-heading {
		font-size: 1.2em;
	}

	.block-line {
		width: 100%;
		display: block;
	}

	.block-margin-twenty {
		margin-right: 20px;
	}

	.block-margin-fourty {
		margin-right: 40px;
	}


	/** Calendar */
	.calendar-block-line {
		width: 100%;
		display: block;
	}

	.calendar-help-label {
		float: left;
	}

	.calendar-help-icon {
		float: right;
	}

	.calendar-tabs {
		clear: both;
	}

	.refresh-block-line {
	}

	.refresh-label {
		display: inline-block;
		margin-right: 10px;
	}

	.refresh-icon {
		display:inline-block;
	}

	/* adapted tree-control.css */
	i.tree-branch-head.node-plus {
		padding: 1px 10px;
		background: url("../../assets/images/node-opened-2.png") no-repeat !important;
	}

	i.tree-branch-head.node-minus {
		padding: 1px 10px;
		background: url("../../assets/images/node-closed-2.png") no-repeat !important;
	}

  .section{
    margin-bottom: 20px;
  }

  .checkbox-fix{
    margin-top: 8px
  }

	.auto-activation {
		input, label {
			display: inline;
		}
		input[type="number"] {
			width: 75px !important;
			margin-top: 13px !important;
		}
	}
}

/** Routinelist */
.component-routine-list {
	table {
		.routine-vehicle-name, .routine-notification {
			white-space: nowrap;
			text-overflow: clip;
		}
	}
}
