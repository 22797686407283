.view-dispolight {
	&.dispolight-container {
		flex-flow: row nowrap; // Need to disable wrapping. This works around a bug in foundation. Otherwise the map will be as long as the (overflowing) list on Firefox and users would have to scroll to see the full map. Support for 'frame' grids might solve that after a foundation upgrade
	}
	.st-content {
		overflow: hidden;
	}
	.grid-toggle {
		position: absolute;
		right: 0; top: 50%;
		background-color: $shiny-edge-active-color;
		z-index: 20000;
		padding-left: rem-calc(4);
		padding-right: rem-calc(4);
		text-align: right;
		i {
			color: $primary-color;
			font-size: 24px;
			margin: auto;
		}
	}
	.routing-panel {
		position: absolute;
		left: 0; top: 0; bottom: 0;
		width: 50%;
		background: $body-bg;
		&.ng-hide-remove, &.ng-hide-add {
			transform: translateX(0);
		}
		&.ng-hide {
			transform: translateX(-100%);
		}
		> a {
			width: 100%;
			margin-bottom: 0;
		}
		@media #{$small-only} {
			width: 100%;
		}
	}
	.dispo-map-wrapper {
		min-height: 200px;
		flex-grow: 1;
		overflow: hidden; // Prevent vertical scrollbars on firefox which cover the vertical resizer bar
	}
	&.resizer-parent-active {
		.dispo-map-wrapper {
			max-width: none; // Override small-x setting to be able to resize past the small-x % share
			flex-grow: 0; // Don't grow when resizing, as the size is set by flex-basis using the resizer component
		}
		.dispo-light-grid {
			min-width: 100px;
			flex-basis: 10px;
		}
	}
	/** Show title of aux columns on mouse-down for desktop interface */
	.aux-icons {
		a {
			position: relative;
			&:active:after {
				content: attr(title);
				position: absolute; top: -3.5em; left: 0em;
				width: 10em;
				z-index: 20000;
				padding: 0.5em;
				border: $joyride-tip-border;
				border-radius: $joyride-tip-radius;
				background: $joyride-tip-bg;
				color: $joyride-tip-font-color;
				font-size: $small-font-size;
			}
		}
	}
	.infostars-map-infobox .aux-icons a:active:after {
		font-size: inherit;
	}
	.dispo-light-grid {
		flex-shrink: 1; flex-grow: 1; // Give up and take size when map is resized
		max-width: none;
	}
	table.dispo-light {
		margin-bottom: 0;
		tr {
			.driver, .gps-time, .speed, .status-time, .address {
				@include small-type;
			}
			td.vehicle-icon {
				text-align: center;
				img {
					max-width: none; // Override the 100% from foundations grid.scss, which causes image downscaling (blurry)
				}
			}
			.vehicle-status {
				white-space: nowrap;
			}
			.resizable-ellipsis {
				&.zone-address {
					max-width: 15em;
				}
				&.vehicle-name {
					max-width: 8em;
				}
			}
			.aux-icons {
				width: (5 * 15px); // 5 icon columns
				min-width: (5 * 15px); // 5 icon columns
				box-sizing: content-box; // Padding added to size
				> div {
					float: left;
				}
			}
			.gps-invalid {
				&, .gps-time {
					color: orange;
				}
			}
		}
		&.col-resizable-resized {
			.resizable-ellipsis {
				&.zone-address, &.vehicle-name {
					max-width: none;
				}
			}
		}
	}
	.result-count, .refresh-timestamp {
		font-weight: normal;
		float: right;
	}
	table.result-count {
		border: 0;
	}
	.refresh-button, .refresh-timestamp {
		float: left;
	}
	.angular-google-map-container img { // Override img styles set by foundation (would break gmaps icons on the map)
		display: initial;
		max-width: none;
	}
	.infostars-map-infobox {
		&, .infostars-map-infobox-header {
			border: 0; margin: 0;
			td {
				vertical-align: middle;
				&.drive-stop-icon { padding-left: 0.5em; }
			}
		}
		td {
			padding: 0;
			& > * {
				font-size: $small-font-size;
			}
		}
	}
	.temperature {
		.ok {
			color: green;
		}
		.warning {
			color: #FE9A2E;
			font-weight: bold;
		}
		.danger {
			color: red;
			font-weight: bold;
		}
	}

	.animal-battery {
		display: block !important;
		min-width: 75px !important;
	}

	.csv-export {
		margin-bottom: 0;
		margin-left: 15px;
		font-size: 70%;
	}
}

.hide-map {
	width: 0 !important;
}

.fixed-column-100 {
	width: 100px;
}
