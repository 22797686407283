.view-maintenance {
	.st-content {
		overflow: hidden;
	}

	.grid-toggle {
		position: absolute;
		right: 0;
		top: 50%;
		background-color: $shiny-edge-active-color;
		z-index: 20000;
		padding-left: rem-calc(4);
		padding-right: rem-calc(4);
		text-align: right;

		i {
			color: $primary-color;
			font-size: 24px;
			margin: auto;
		}
	}

	.maintenance-container {
		flex-flow: row nowrap; // Need to disable wrapping. This works around a bug in foundation. Otherwise the map will be as long as the (overflowing) list on Firefox and users would have to scroll to see the full map. Support for 'frame' grids might solve that after a foundation upgrade
	}

	.maintenance-light-grid {
		flex-shrink: 1;
		flex-grow: 1; // Give up and take size when map is resized
		max-width: none;
	}

	.option-btn {
		margin-bottom: 0;
	}

	iframe.maintenance {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.maintenance-action-panel {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
		button {
			margin-bottom: 3px;
			cursor: pointer;
		}
	}

	.no-results-container {
		top: inherit !important;
	}

	#editor {
		border-left: 2px solid #EAEAEA;
		min-height: 500px;

		textarea {
			resize: none;
		}
	}

	.mbottom20 {
		margin-bottom: 20px !important;
	}

	#maintenanceForm.ng-submitted {
		input.ng-invalid, textarea.ng-invalid {
			margin-bottom: 0;
		}

		.invalid-text {
			color: #c90000;
			font-size: 12px;
			margin-bottom: 11px;
		}
	}

	#reportForm button {
		margin-left: 0.9375rem;
		transition: none !important;
	}

	.pagination {
		input {
			width: 60px;
			text-align: center;
		}

		.page-number {
			padding-top: 3px;
			font-size: 15px;
		}
	}

	#maintenanceTable {
		td {
			a {
				margin-bottom: 0;
			}
		}

		tbody {
			tr {
				td {
					font-size: 0.75rem;
				}
				td.infotext {
					max-width: 10em;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}
	}

	.inline {
		display: inline;
	}

	/* Large and up */
	@media screen and (max-width: 1199px) {
		#listPanel {
			&.sharing {
				display: none !important;
			}
		}
	}

	@media only screen and (min-width: 64.063em) {
		#maintenanceTable {
			tbody {
				tr {
					td {
						font-size: 0.825rem;
					}
				}
			}
		}
	}
}
