.default-tour-status-section {
	button {
		width: 100%;
		cursor: pointer;
	}

	.not-active {
		background-color: white;
		border: 1px solid #db6314;
		color: #db6314;
	}
}
