.show-animation.ng-hide-remove,
.hide-animation.ng-hide-add,
.show-hide-animation.ng-hide-add,
.show-hide-animation.ng-hide-remove {
	@include single-transition(all, 0.5s, linear);
	/* remember to add this */
	display:block!important;
	opacity:1;
}
.show-animation.ng-hide,
.hide-animation.ng-hide,
.show-hide-animation.ng-hide {
	opacity:0;
}
