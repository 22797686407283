$filter-item-padding: 1em 1em 1em 1.2em;

.filter-component {
	.from-to-filter .date-time .time-column {
		padding-left: 0;
	}
}

.row.slim-gutter,
.main-conditions, .secondary-conditions, .zone-filter,
.static-routes-filter, .map-settings, .map-section {
	.column {
		padding-left: 0;
	}
}

.st-menu { /** XXX Replace with grid-frame, grid-block etc. for a proper vertical layout */
	overflow-y: scroll;
	.st-menu-content {
		padding: $filter-item-padding;
	}
}

.st-content {
	min-height: 100vh;
}


/** Style from http://tympanus.net/Development/SidebarTransitions/ */
.st-container,
.st-pusher,
.st-content {
	height: 100%;
}

.st-content {
	overflow-y: scroll;
}

.st-content,
.st-content-inner {
	position: relative;
}

.st-container {
	position: relative;
	overflow: hidden;
}

.st-pusher {
	position: relative;
	left: 0;
	z-index: 99;
	height: 100%;
	perspective: 1000px;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.st-pusher::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-open .st-pusher::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	visibility: hidden;
	width: 300px;
	height: 100%;
	background: $st-menu-bg;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.st-menu::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu-open .st-menu::after {
	width: 0;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

/* Effect 7: 3D rotate in */
.st-effect-7.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-7 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-7.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-7.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(90deg);
	transform: translate3d(-100%, 0, 0) rotateY(90deg);
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-7.st-menu-open .st-effect-7.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
	transform: translate3d(-100%, 0, 0) rotateY(0deg);
}

/* content style */
.st-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.st-menu h2 {
	margin: 0;
	padding: 1em;
	color: rgba(0,0,0,0.4);
	text-shadow: 0 0 1px rgba(0,0,0,0.1);
	font-weight: 300;
	font-size: 2em;
}
.st-menu ul li.accordion-navigation a:not(.button, .link) {
	display: block;
	padding: $filter-item-padding;
	outline: none;
	box-shadow: inset 0 -1px rgba(0,0,0,0.2);
	text-transform: uppercase;
	text-shadow: 0 0 1px rgba(255,255,255,0.1);
	letter-spacing: 1px;
	font-weight: 400;
	-webkit-transition: background 0.3s, box-shadow 0.3s;
	transition: background 0.3s, box-shadow 0.3s;
}
.st-menu ul li button {
	width: 100%;
	text-align: left;
}
.st-menu ul li:first-child a {
	box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
}
.st-menu ul li a:hover:not(.button, .link) {
	background: rgba(0,0,0,0.2);
	box-shadow: inset 0 -1px rgba(0,0,0,0);
	color: #fff;
}

.driver-checkbox {
	padding-left: 35px;
}
