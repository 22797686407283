.view-taxilog {
	.log-map-wrapper {
		height: 250px;
		position: relative;
	}
	.st-content {
		overflow: hidden;
	}
	ui-gmap-google-map {
		position: absolute;
		left: 0; right: 0; top: 0; bottom: 0;
	}
	.angular-google-map, .angular-google-map-container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	.taxilog-map-buttons {
		float: left;
		width: 100%;
		padding-left: $form-spacing * .5;
		form {
			float: left;
		}
		.export-csv.button {
			float: right;
			margin-bottom: 0;
		}
		.notice {
			display: inline-block;
		}
	}
	.map-taxilog-control {
		background-color: rgba(245, 245, 245, 0.7); // Duplicate the one from google on the bottom of the map
		&.taxilog-map-confirm-move {
			button {
				margin-bottom: 0;
			}
		}
	}
	.grouping-and-infos {
		@media #{$large-up} {
			// ATTENTION: COPY OF CODE BELOW FOR PRINT - KEEP IN SYNC
			// Make sure the table cells are properly horizontally aligned on big screens
			.expand { width: 1em; }
			table.grouping {
				table-layout: fixed;
				.title {
					width: 12em;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.fees {
					width: 12em;
				}
			}
		}
		.warnings-col {
			width: 7em;
		}
		.grouping {
			margin-bottom: 0;
			border-bottom: 0;
		}
		.grouping.forced-valid {
			td {
				background-color: scale-color($warning-color, $lightness: 90%);
			}
		}
		.grouping.confirmed, .infos .confirmed {
			td {
				background-color: scale-color($success-color, $lightness: 90%);
			}
		}
		.grouping.invalid, .infos .invalid {
			td {
				background-color: scale-color($alert-color, $lightness: 90%);
			}
		}
	}
	.grouping-and-infos:last-child {
		.grouping {
			border-bottom: $table-border-style $table-border-size $table-border-color;
		}
	}
	@media print {
		table .actions { display: none; }
		.log-map-wrapper { display: none; }
		.button { display: none; }
		.warning .fi-check { display: none; }
		font-size: rem-calc(10);
		table tr th, table tr td {
			font-size: rem-calc(10);
		}
		
		// ATTENTION: COPY OF CODE ABOVE - KEEP IN SYNC
		.grouping-and-infos {
			// Make sure the table cells are properly horizontally aligned on big screens
			.expand { width: 1em; }
			table.grouping {
				table-layout: fixed;
				.title {
					width: 12em;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.fees {
					width: 12em;
				}
			}
		}
		
		.grouping-and-infos .warnings-col { width: 16em; }
	}
}
