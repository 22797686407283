.component-zone-list {
	&.grid-block {
		flex-wrap: nowrap; // Prevent google map from becoming full-height on desktop
		> .grid-block {
			margin-left: 0; margin-right: 0;
		}
	}
	> .grid-content, > .grid-block {
		flex-grow: 1; flex-shrink: 1;
	}
	> .grid-content {
		flex-shrink: 0.6;
	}
	.table-container {
		position: relative; // Restrict the loading spinner and no-result icon to the table
	}
}
.component-account-list {
	table > tbody > tr > td,
	table > thead > tr > th {
		.account-name {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
		}
	}
}
.component-vehicle-list {
	overflow-y: auto; // XXX This is a hack for the fact that .grid-block has overflow-y: auto during development, but overflow-y: hidden when building
}
.component-maintenance-list {
	overflow-y: auto; // XXX This is a hack for the fact that .grid-block has overflow-y: auto during development, but overflow-y: hidden when building
}
.table-filter {
	input, .postfix {
		&, &:hover, &:focus {
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
	.postfix {
		border-right: 0;
		&.active-sidebar-filter {
			background-color: scale-color($primary-color, $lightness: 80%);
		}
	}
}

.calendar-tabs-fix{
  float: left;
  margin-right: 4px ;
  margin-bottom:5px;
}

#maintenanceTable {
	tbody {
		tr {
			td {
				font-size: 0.75rem;
			}
		}
	}
}

@media only screen and (min-width: 64.063em) {
	#maintenanceTable {
		tbody {
			tr {
				td {
					font-size: 0.825rem;
				}
			}
		}
	}
}
