.title-area .name h1.menu-icon a {
	@include hamburger(16px, false, 0, 1px, 6px, $topbar-menu-icon-color, "", false);
	// From _top-bar.scss:236 .toggle-topbar a
	padding: 0 $topbar-link-padding 0 $topbar-link-padding+rem-calc(25);
	text-transform: $topbar-menu-link-transform;

	span::after { // Change the side
		right: auto; left: $topbar-link-padding;;
	}
}

.forced-account-username {
	font-size: 0.6rem;
	line-height: 2rem;
}

li.has-dropdown > a {
	cursor: default; // When we have sub-menus the button shows the sub-menu on hover and is not clickable, so prevent the hand-cursor from showing
}
