.alarm-container {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid rgb(203, 203, 203);
}

.alarm-container:first-of-type {
	border-top: 1px solid rgb(203, 203, 203);
}

.expired-desc {
	font-size: 12px;
}
