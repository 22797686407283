.vehicle-editor {
	.vehicle-picture img {
		vertical-align: top;
		height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1)) - 2*rem-calc(1); // See _forms.scss prefix-postfix-base height - 2*border-width
	}
	.icon-number-color .colorpicker {
		float: right;
	}

	.rotatable {
		vertical-align: super;
		font-size: x-small;
	}
}
