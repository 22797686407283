.logbook-sidebar {
	table {
		border-left: 0; border-right: 0; border-bottom: 0;
		th { text-align: center; }
		tr {
			td {
				padding: rem-calc(4 5); // $table-row-padding / 4;
			}
			td, span {
				&.title {
					font-weight: bold;
					white-space: nowrap;
				}
				&:not(.title) {
					@include small-type;
				}
				&.icon {
					text-align: center;
				}
			}
			td:nth-of-type(2) {
				width: 100%; // The data column should get all the remaining width (nowrap for the title)
			}
			&:nth-of-type(even) { background: $table-bg; } // Disable the stripes
		}
	}
	.day-switcher {
		max-height: 10em;
		overflow-y: scroll;
		margin-bottom: $table-margin-bottom;
		text-align: center;
	}
	.actions {
		text-align: center;
	}
}