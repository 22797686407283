.maintenance-alarm-editor {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 3px solid rgb(203, 203, 203);
}

.maintenance-alarm-editor:nth-child(even) {
	background-color: rgba(203, 203, 203, 0.15);
}

.maintenance-alarm-editor:first-of-type {
	border-top: 3px solid rgb(203, 203, 203);
}

.notifications {
	margin-top: 20px;
}

.notifications > div {
	font-weight: bold;
}
