.view-youthtaxi-signup {
	.language-chooser-wrapper {
		margin-top: $form-spacing;
	}
	.image, form.dropzone {
		margin-bottom: $form-spacing;
	}
	.image {
		width: 120px; height: 120px * 45/35; // Passport photo aspect ratio
		background-size: cover;
		background-position: 50% 50%;
		border: 2px solid $body-font-color;
	}
	
}