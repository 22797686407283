.vehicle-group-editor {
	.loose-and-tree {
		margin-bottom: $form-spacing;
	}
	.make-loose-droptarget {
		border: $fieldset-border-width $fieldset-border-style $fieldset-border-color;
		margin-bottom: $form-spacing;
	}
	ul.vehicle-tree ul.tree-node > li {
		margin-bottom: 0; // No need to separate vehicles from the next group, we don't have any
	}
	.acidjs-css3-treeview {
		margin: 0;
		label > input {
			display: none; // Hide the group checkbox input
		}
		label span::before {
			color: black;
			border-color: black;
		}
		label span::before {
			visibility: hidden; // Hide the checkbox widget
		}
		label[for]::before {
			color: black;
			margin-right: -40px; // Move the Text closer to the expand / collapse widget
		}
	}
}