@mixin small-type() {
	font-size: $small-font-size;
	color: $small-font-color;
	line-height: 1;
}
@mixin inherit-type() {
	font-size: inherit;
	color: inherit;
	line-height: inherit;
}
@mixin small-type-table() {
	@include small-type();
	line-height: 0;
}
