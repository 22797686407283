.map-attribution-control {
	// Duplicate the ones from google on the bottom of the map
	background-color: rgba(245, 245, 245, 0.7);
	padding-right: 6px;
	padding-left: 6px;
	font-family: Roboto, Arial, sans-serif;
	font-size: 10px;
	color: rgb(68, 68, 68);
	line-height: 14px;
}
.map-attribution {
	margin-right: 1em;
}