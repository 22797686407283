input.map-searchbox {
	// Duplicate the map type switcher style from google at the top left of the map
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	margin-top: 10px;
	margin-left: 0px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 300px;
	border: 1px solid transparent;
	border-radius: 2px 0 0 2px;
	background-color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	height: 32px;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	&:focus {
		border-color: #4d90fe;
	}
}
@media #{$large-only} { // Only shown from large-upwards
	input.map-searchbox.narrow {
		width: 150px;
	}
}
