$filter-item-padding: 1em 1em 1em 1.2em;

ul.old-vehicle-tree {
	ul.tree-node {
		& > li {
			margin-bottom: 1em; // Separate the last vehicle from the next heading
		}
		li.tree-node {
			margin-left: 10px;
		}
	}
	li.tree-node {
		> label {
			font-weight: bold;
		}
	}
	li.tree-leaf {
		margin-left: 20px;
		& > span {
			font-size: $small-font-size;
		}
		label span::before {
			transform: initial; // autoprefix will generate vendor prefixes
			-ms-transform: translateX(0px); // IE does not have initial, see http://caniuse.com/#feat=css-initial-value
			vertical-align: middle;
			margin: 0;
		}
		label:not([for]) {
			margin-right: 2px;
		}
		.img-wrapper {
			display: inline-block;
			img {
				min-width: 32px; min-height: 32px;
			}
		}
	}
}

.acidjs-css3-treeview {
	label {
		display: inline; // Reset from _forms.scss
		vertical-align: top; // Make sure the :before elements for the toggle triangle and the checkbox align vertically to the label
	}
}

.acidjs-css3-treeview > li.tree-node > ul.tree-node {
	margin-left: 0;
}


/** Style from http://experiments.wemakesites.net/css3-treeview-with-multiple-node-selection.html */
.acidjs-css3-treeview {
	font: normal 11px/16px "Segoe UI", Arial, Sans-serif;
	* {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	label {
		&[for] {
			display: inline-block;
		}
		&[for]::before, span::before {
			content: "\25b6";
			display: inline-block;
			margin: 0.4em 0 0;
			width: 13px;
			height: 13px;
			vertical-align: top;
			text-align: center;
			color: $body-font-color;
			font-size: 8px;
			line-height: 13px;
		}
		&[for]::before { // The arrow to expand / collapse
			padding-top: 0.1em;
			-webkit-transform: translatex(-24px);
			-moz-transform: translatex(-24px);
			-ms-transform: translatex(-24px);
			-o-transform: translatex(-24px);
			transform: translatex(-24px);
		}
		span::before { // The checkbox
			-webkit-transform: translatex(16px);
			-moz-transform: translatex(16px);
			-ms-transform: translatex(16px);
			-o-transform: translatex(16px);
			transform: translatex(16px);
		}
	}

	li ul {
		margin: 0 0 0 0em;
	}

	* {
		vertical-align: middle;
	}

	li {
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	input[type="checkbox"] {
		display: none;
	}

	label {
		cursor: pointer;
	}

	input[type="checkbox"][id]:checked ~ label[for]::before {
		content: "\25bc";
	}

	input[type="checkbox"][id]:not(:checked) ~ ul {
		display: none;
	}

	label:not([for]) {
		margin: 0 8px 0 0;
	}

	label span::before {
		content: "";
		margin: 0.1em 0 0;
		border: solid 1px $body-font-color;
		color: $body-font-color;
		opacity: .50;
	}

	label input:checked + span::before {
		content: "\2714";
		box-shadow: 0 0 2px rgba(0, 0, 0, .25) inset;
		opacity: 1;
	}
}
