.taxilog-sidebar {
	.controls {
		text-align: center;
		a.button {
			&.prev { float: left; }
			&.next { float: right; }
		}
		form {
			width: 100%;
			float: left;
		}
	}
	table.driver-group-infos {
		border-left: 0; border-right: 0;
	}
	table.sidebar-values {
		border-left: 0; border-right: 0; border-bottom: 0;
		tr {
			td {
				padding: rem-calc(4 5); // $table-row-padding / 4;
			}
			td, span {
				&.title {
					font-weight: bold;
					white-space: nowrap;
				}
				&:not(.title) {
					@include small-type;
				}
				&.icon {
					text-align: center;
				}
			}
			td:nth-of-type(2) {
				width: 100%; // The data column should get all the remaining width (nowrap for the title)
			}
			&:nth-of-type(even) { background: $table-bg; } // Disable the stripes
		}
	}
}