/** Application wide rules for printing */
@media print {
	// Hide unnecessary elements for printing
	.top-bar, .messages-container, .report-export.button, .map-section-control, .logbook-section .actions .button, .hotkeys-hint {
		display: none !important;
	}
	// ===============================
	// Make sure everything is on screen and scrollable from <body> (tested on logbook)
	.grid-frame {
		display: block !important;
		overflow: visible !important;
		height: auto !important;
	}
	.st-container, .st-pusher {
		height: auto !important;
	}
	.st-container {
		overflow: visible !important;
	}
	.print-only { // The regular print-only sets it to block, but we need it on spans as well
		display: initial !important;
	}
	// ===============================
}
