table thead tr th.col-resizable {
	&:hover {
		position: relative;
		&::after {
			content: "";
			width: 3px;
			cursor: w-resize;
			position: absolute;
			top: 0; right: 0;
			height: 100%;
			z-index: 10;
			display: block;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
	&.col-resizable-resized {
		background-color: rgba(0, 0, 0, 0.04);
	}
}
table td .resizable-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

table th .resizable-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
