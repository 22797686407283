.zone-logbook-section {
	table td .no-prev-outside {
		color: $body-font-color-dimmed !important; // Important to override black color in print media query
	}
	img.logo {
		width: 265px;
		height: 75px;
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0.5em;
	}
}

@media print {
	.zone-logbook-section {
		.chart {
			canvas { // Fixes size of the chart for printing, see https://github.com/chartjs/Chart.js/issues/1350#issuecomment-1369173511
				width: 100% !important;
				height: auto !important;
			}
		}
		// Fixes page breaks within lines of a row, making the row unreadable on Firefox
		table[role=grid] tr td, table tr th {
			break-inside: avoid;
		}
		// Make sure the duration is well readable (seconds aligned underneath seconds etc.) when printed
		table .duration {
			text-align: right;
		}
	}
}
